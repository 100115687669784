import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Song from "../views/Song.vue";
import List from "../views/List.vue";
import Paste from "../views/Paste.vue";
import Profile from "../views/Profile.vue";
import Login from "../views/Login.vue";
import Reset from "../views/Reset.vue";
//import { auth } from "../firebase";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requresAuth: false
    }
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import("../views/About.vue"),
    meta: {
      requresAuth: false
    }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import("../views/Privacy.vue"),
    meta: {
      requresAuth: false
    }
  },
  {
    path: "/library",
    name: "library",
    component: List,
    meta: {
      requresAuth: true
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      requresAuth: true
    }
  },
  {
    path: "/song/:id?",
    name: "song",
    component: Song,
    meta: {
      requresAuth: true
    }
  },
  {
    path: "/paste",
    name: "paste",
    component: Paste,
    meta: {
      requresAuth: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requresAuth: false
    }
  },
  {
    path: "/reset",
    name: "reset",
    component: Reset,
    meta: {
      requresAuth: false
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const currentUser = auth.currentUser;
//   const requiresAuth = to.matched.some(record => record.meta.requresAuth);
//   if (to.path === "/login" && currentUser) {
//     next("/");
//   }
//   else if (requiresAuth && !currentUser) {
//     next("/login");
//     return;
//   }
//   next();
// });

export default router;
