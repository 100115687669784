<template>
  <w-progress v-if="isLoading" class="ma0 pa0" color="green"></w-progress>
  <main class="login">
    <section class="forms">
      <w-form
        class="register"
        @submit.prevent="handleRegister"
        :disabled="isLoading"
      >
        <h2>Register</h2>
        <w-input
          type="email"
          placeholder="Email"
          v-model="register_form.email"
          class="mb5"
        />
        <w-input
          type="password"
          placeholder="Password"
          v-model="register_form.password"
          class="mb5"
        />
        <w-button type="submit" value="Register" :disabled="isLoading"
          >Register</w-button
        >
      </w-form>
      <w-form class="login" @submit.prevent="handleLogin" :disabled="isLoading">
        <h2>Login</h2>
        <w-input
          type="email"
          placeholder="Email"
          v-model="login_form.email"
          class="mb5"
        />
        <w-input
          type="password"
          placeholder="Password"
          v-model="login_form.password"
          class="mb5"
        />
        <w-button type="submit" value="Login" :disabled="isLoading"
          >Login</w-button
        >
        <w-button
          type="button"
          value="Reset Password"
          :disabled="isLoading"
          class="ml2"
          route="/reset"
          >Reset</w-button
        >
        <div v-if="error" class="error">{{ error }}</div>
      </w-form>
    </section>
    <section class="text-center">
      <w-button
        @click="handleGoogleLogin"
        class="google pa4 ma2"
        :disabled="isLoading"
      >
        <w-icon class="mr2">mdi mdi-google</w-icon>
        <span>Login with Google</span>
      </w-button>
      <w-button
        @click="handleFacebookLogin"
        class="google pa4 ma2"
        :disabled="isLoading"
      >
        <w-icon class="mr2">mdi mdi-facebook</w-icon>
        <span>Login with Facebook</span>
      </w-button>
    </section>
  </main>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
//import { useRouter } from "vue-router";

export default {
  setup() {
    const login_form = ref({});
    const register_form = ref({});
    const store = useStore();
    let isLoading = ref(true);
    //const router = useRouter();
    const error = ref(null);

    const handleGoogleLogin = async () => {
      try {
        await store.dispatch("googleLogin");
      } catch (e) {
        error.value = e.message;
      }
    };

    const handleFacebookLogin = async () => {
      try {
        await store.dispatch("facebookLogin");
      } catch (e) {
        error.value = e.message;
      }
    };

    const handleLogin = async () => {
      try {
        console.log("login_form", login_form.value);
        await store.dispatch("login", login_form.value);
      } catch (e) {
        error.value = e.message;
      }
    };

    const handleRegister = async () => {
      try {
        await store.dispatch("register", register_form.value);
      } catch (e) {
        error.value = e.message;
      }
    };

    const handleLogout = async () => {
      try {
        console.log("logout");
        await store.dispatch("logout");
      } catch (e) {
        error.value = e.message;
      }
    };

    onBeforeMount(async () => {
      isLoading.value = true;
      await store.dispatch("init");
      isLoading.value = false;
    });

    return {
      login_form,
      register_form,
      handleGoogleLogin,
      handleFacebookLogin,
      handleLogin,
      handleRegister,
      handleLogout,
      error,
      isLoading,
    };
  },
};
</script>

<style scoped>
.forms {
  display: flex;
  min-height: 30vh;
}
form {
  flex: 1 1 0%;
  padding: 4rem 1rem 1rem;
}
form.register {
  color: #555;
  /* background-color: rgb(255, 115, 0); */
}
form.login {
  color: #555;
}
h2 {
  text-transform: uppercase;
  margin-bottom: 2rem;
}
</style>
