import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBN3XlLcceB9s2zrgaPBTIhRo1M_KYQRS4",
  authDomain: "app.songsmith.ai",
  projectId: "songsmith-ai",
  storageBucket: "songsmith-ai.appspot.com",
  messagingSenderId: "934466466765",
  appId: "1:934466466765:web:918fc29e8bd07190bb0a84",
  measurementId: "G-QE4RGTYFDG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app);

// this allows us to run the local dev server for hotlaoding while also using the firebase functions emulator
if (window.location.hostname === "localhost") {
  console.log(
    "testing locally -- with firebase emulators"
  );
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export {
  auth,
  analytics,
  functions,
  db,
}