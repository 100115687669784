<template>
  <w-app>
    <!--Main Navigation-->
    <header>
      <w-toolbar shadow class="py1" height="5em">
        <w-icon color="light-blue-dark3" size="2.5em"
          >mdi mdi-robot-angry-outline</w-icon
        >
        <h1>
          <w-button height="100%" lg text class="" route="/library"
            >songsmith.ai</w-button
          >
        </h1>
        <div class="spacer"></div>

        <!-- <w-button height="100%" md text class="" route="/about">About</w-button> -->
        <w-button height="100%" md text class="" route="/profile" v-if="user">
          Profile</w-button
        >
        <w-button height="100%" md text class="" route="/library" v-if="user"
          >Library</w-button
        >
        <w-button
          v-if="authReady && user"
          height="100%"
          md
          text
          class=""
          @click="$store.dispatch('logout')"
          >Logout</w-button
        >
        <w-button height="100%" md text class="" route="/login" v-else
          >Login</w-button
        >
      </w-toolbar>
    </header>
    <!--Main Navigation-->

    <!--Main layout-->
    <main class="grow">
      <router-view />
    </main>
    <!--Main layout-->

    <!--Footer-->
    <footer>
      <w-toolbar shadow class="py1" height="3em">
        <div class="spacer"></div>
        <img v-if="user" width="35" class="mr1" :src="userPhoto()" />
        <span v-if="user">{{ user.email }}</span>
      </w-toolbar>
    </footer>
    <!--Footer-->
  </w-app>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import md5 from "md5";

export default {
  data() {
    return {};
  },
  setup() {
    const store = useStore();

    const handleLogout = async () => {
      store.dispatch("logout");
    };

    const userPhoto = () => {
      const user = store.state.user;
      if (user) {
        const hash = md5(user.email);
        const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?s=35&d=robohash&f=y`;
        return gravatarUrl;
      }
    };

    return {
      handleLogout,
      user: computed(() => store.state.user),
      authReady: computed(() => store.state.authReady),
      userPhoto,
    };
  },
  components: {},
};
</script>
<style>
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.5em;
  background-color: #234781;
}

section#main-container {
  height: 100%;
}

header .w-toolbar,
footer .w-toolbar {
  background-color: lightgrey;
}

header h1 {
  margin: 0;
  padding: 3px 0 0 10px;
  font-size: 1.2em;
  font-weight: normal;
}

main {
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

main h1 {
  margin: 0;
  padding: 10px;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

footer {
  min-height: 60px;
}

#wrapper {
  overflow: hidden;
}

p {
  color: #555;
  padding: 10px;
  margin-bottom: 1em;
}

.hidden {
  display: none;
}

.huge {
  font-size: 3em;
  font-weight: bold;
  color: #555;
}
</style>