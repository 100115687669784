<template>
  <w-progress v-if="isLoading" class="ma0 pa0" color="green"></w-progress>
  <main class="login">
    <section class="successMessage" v-if="resetSuccess">
      <h2>Password Reset</h2>
      <p>
        An email has been sent to your email address. Please check your email
        and follow the instructions to reset your password.
      </p>
      <p>
        <w-button class="secondary" route="/login"> Login </w-button>
      </p>
    </section>
    <section v-else>
      <w-form class="reset" @submit.prevent="handleReset" :disabled="isLoading">
        <h2>Reset Password</h2>
        <w-input
          type="email"
          placeholder="Email Address"
          v-model="email"
          class="mb5"
        />
        <w-button type="submit" value="Reset" :disabled="isLoading"
          >Reset Password</w-button
        >
      </w-form>
    </section>
  </main>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
//import { useRouter } from "vue-router";

export default {
  setup() {
    const email = ref("");
    let isLoading = ref(true);
    let resetSuccess = ref(false);
    //const router = useRouter();
    const error = ref(null);

    onBeforeMount(async () => {
      isLoading.value = true;
      isLoading.value = false;
    });

    const handleReset = async () => {
      isLoading.value = true;
      var email_address = email.value;
      sendPasswordResetEmail(auth, email_address)
        .then(function () {
          // Email sent.
          isLoading.value = false;
          resetSuccess.value = true;
          error.value = null;
        })
        .catch(function (error) {
          alert("NO");
          console.log(error);
          // An error happened.
          isLoading.value = false;
          error.value = error.message;
        });
      isLoading.value = false;
    };

    return {
      email,
      handleReset,
      error,
      isLoading,
      resetSuccess,
    };
  },
};
</script>

<style scoped>
form {
  flex: 1 1 0%;
  padding: 4rem 1rem 1rem;
  color: #555;
}
h2 {
  text-transform: uppercase;
  margin-bottom: 2rem;
}
</style>
