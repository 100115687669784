<template>
  <w-form
    no-blur-validation
    no-keyup-validation
    @submit.prevent="createSong"
    v-if="user && !songId && isAuthorized"
    v-model="formValid"
  >
    <w-card class="pa3 ma2">
      <w-flex wrap class="song-init justify-start no-grow">
        <div class="xs12 mb5">
          <div class="title1">
            New song
            <w-button
              sm
              ml2
              @click="generateSongInit"
              :disabled="isLoading"
              id="generate-initial"
              icon="mdi mdi-cog-play"
              title="Generate a song idea and title for me"
            >
            </w-button>
          </div>
          <div class="xs12 mt5 body">
            Before you can start writing lyrics, you need to enter a description
            and a title. If you are not sure what you are going to write about,
            click the gear icons to ask your AI assistant for suggestions.
          </div>
        </div>
        <div class="xs12 pa3">
          <w-select
            :menu-props="{ appendTo: '.form-drawer' }"
            v-model="custom_model"
            :items="custom_models"
            :disabled="isLoading"
            >Select a model
          </w-select>
        </div>
        <!-- <div class="xs6 pa3">
          <w-select
            :menu-props="{ appendTo: '.form-drawer' }"
            v-model="custom_model"
            :items="custom_models"
            >Model
          </w-select>
        </div> -->
        <div class="xs11 pa3">
          <w-textarea
            id="summary"
            v-model="summary"
            label="This is a song about"
            placeholder="rowing a boat gently down the stream."
            :validators="[validators.required]"
            update:model-value="summaryChanged"
            :disabled="isLoading"
            rows="1"
          >
          </w-textarea>
        </div>
        <div class="xs1 pa3 text-center">
          <w-button
            sm
            mr2
            @click="generateSummary"
            :disabled="isLoading || !custom_model"
            id="generate-summary"
            icon="mdi mdi-cog-play"
            title="Generate a song idea for me"
          >
          </w-button>
        </div>
        <div class="xs11 pa3">
          <w-input
            id="title"
            v-model="title"
            label="Song Title"
            placeholder="Row Row Row Your Boat"
            :disabled="isLoading"
            :validators="[validators.required]"
          />
        </div>
        <div class="xs1 pa3 text-center">
          <w-button
            sm
            @click="generateTitle"
            :disabled="isLoading || !summary"
            id="generate-title"
            class="justify-self-end"
            icon="mdi mdi-cog-play"
            title="Generate a title for me based upon the song description"
          >
          </w-button>
        </div>
        <div class="xs2 text-left mt5"></div>
        <div class="xs10 text-right mt5">
          <w-button
            type="submit"
            lg
            text
            shadow
            bg-color="primary"
            class="ma2"
            :disabled="isLoading || formValid === false || !summary || !title"
          >
            Create Song</w-button
          >
          <w-button
            lg
            text
            shadow
            bg-color="secondary"
            class="ma2"
            :route="'/library'"
          >
            Cancel</w-button
          >
        </div>
      </w-flex>
    </w-card>
  </w-form>
  <div
    class="song"
    v-if="songId"
    v-bind:class="{ loading: isLoading, hidden: !user }"
  >
    <w-form v-model="formValid" no-blur-validation no-keyup-validation>
      <w-card>
        <w-flex align-center class="wrapper">
          <span class="song-title" @click="openFormDrawer = 'top'">{{
            title
          }}</span>
          <w-button
            @click="openFormDrawer = 'top'"
            icon="mdi mdi-arrow-down-drop-circle-outline"
            text
          ></w-button>
          <div class="spacer"></div>
          <!-- <span v-if="isLoading" class="info msg"
          ><w-progress class="mt1 mr4" circle size="18"></w-progress
        ></span> -->
          <w-button
            @click="
              generateLyrics('line');
              openFormDrawer = false;
            "
            :loading="isLoading"
            class="mr1 pa4"
            title="Generate the next line of lyrics"
            xs
          >
            + Line
          </w-button>
          <w-button
            @click="
              generateLyrics('section');
              openFormDrawer = false;
            "
            :loading="isLoading"
            class="mr1 pa4"
            title="Generate the next section of lyrics"
            xs
          >
            + Section
          </w-button>
          <w-button
            @click="
              generateLyrics('remaining');
              openFormDrawer = false;
            "
            :loading="isLoading"
            class="mr1 pa4"
            title="Generate all lyrics until the end of the song"
            xs
          >
            + Remain
          </w-button>
          <!-- <w-button
            @click="
              generateLyrics('all');
              openFormDrawer = false;
            "
            :loading="isLoading"
            class="mr1 pa4"
            xs
          >
            Full Song
          </w-button> -->
          <!-- <w-button
            @click="generateAll"
            :loading="isLoading"
            class="mr1 pa4"
            id="generate-all"
            xs
            >Lucky?
          </w-button> -->
        </w-flex>
        <div v-if="errorMessage && isAuthorized">
          <div class="pa2 mt2">
            <w-alert class="pa1 ma0" error icon-outside>{{
              errorMessage
            }}</w-alert>
          </div>
        </div>
        <div v-if="infoMessage && !errorMessage && isAuthorized">
          <div class="pa2 mt2">
            <w-alert class="pa1 ma0" info icon-outside>{{
              infoMessage
            }}</w-alert>
          </div>
        </div>
        <div id="not_authorized" v-if="!isLoading && !isAuthorized">
          <div class="pa2 mt2">
            <w-alert class="pa1 ma0" warning icon-outside>
              You are not authorized for access. Please contact
              <a href="mailto:support@songsmith.ai">support@songsmith.ai</a> for
              more information.
            </w-alert>
          </div>
        </div>

        <w-drawer
          class="form-drawer"
          v-model="openFormDrawer"
          :[drawerPosition]="true"
          fit-content
        >
          <!-- <w-button
            @click="openFormDrawer = false"
            sm
            outline
            round
            absolute
            icon="wi-cross"
          >
          </w-button> -->
          <w-flex wrap>
            <div class="xs4 pa3">
              <w-select
                :menu-props="{ appendTo: '.form-drawer' }"
                v-model="custom_model"
                :items="custom_models"
                >Select model
              </w-select>
            </div>
            <div class="xs7 pa3">
              <w-textarea
                id="summary"
                v-model="summary"
                label="This is a song about"
                update:model-value="summaryChanged"
                :validators="[validators.required]"
                rows="1"
              >
              </w-textarea>
            </div>
            <div class="xs1 pa3 text-center">
              <w-button
                md
                mr2
                @click="generateSummary"
                :disabled="isLoading"
                id="generate-summary"
                title="Generate a song idea for me based upon the model"
                icon="mdi mdi-cog-play"
              >
              </w-button>
            </div>
            <!-- <div class="xs6 pa3">
              <w-select
                :menu-props="{ appendTo: '.form-drawer' }"
                v-model="custom_model"
                :items="custom_models"
                >Select model
              </w-select>
            </div> -->
            <!-- <div class="xs5">
            </div> -->
            <div class="xs7 pa3">
              <w-input
                id="title"
                v-model="title"
                label="Song Title"
                :validators="[validators.required]"
              />
            </div>
            <div class="xs1 pa3 text-center">
              <w-button
                md
                @click="generateTitle"
                :disabled="isLoading"
                id="generate-title"
                title="Generate a song title for me based upon the description"
                class="justify-self-end"
                icon="mdi mdi-cog-play"
              >
              </w-button>
            </div>
            <div class="xs4 pa3">
              <div class="pa0 primary body">
                Temperature
                <w-icon
                  md
                  color="primary-dark1"
                  title="Randomness selector. Higher values are more random."
                >
                  mdi mdi-help-circle
                </w-icon>
              </div>
              <w-flex align-center class="pa0">
                <w-button
                  @click="user_temp -= 0.05"
                  icon="wi-minus"
                  bg-color="primary"
                  sm
                >
                </w-button>
                <w-slider
                  class="mx3 grow"
                  v-model="user_temp"
                  min="0"
                  max="1"
                  step=".05"
                  color="primary"
                >
                </w-slider>

                <w-button
                  @click="user_temp += 0.05"
                  icon="wi-plus"
                  bg-color="primary"
                  sm
                >
                </w-button>
              </w-flex>
            </div>
            <div class="xs12 text-center">
              <w-button
                @click="
                  generateLyrics('line');
                  openFormDrawer = false;
                "
                :disabled="isLoading"
                class="mr1 pa4"
                title="Generate the next line of lyrics"
                xs
              >
                + Line
              </w-button>
              <w-button
                @click="
                  generateLyrics('line');
                  openFormDrawer = false;
                "
                :disabled="isLoading"
                class="mr1 pa4"
                title="Generate the next section of lyrics"
                xs
              >
                + Section
              </w-button>
              <w-button
                @click="
                  generateLyrics('remaining');
                  openFormDrawer = false;
                "
                :disabled="isLoading"
                class="mr1 pa4"
                title="Generate all lyrics until the end of the song"
                xs
              >
                + Remain
              </w-button>
              <!-- <w-button
                @click="
                  generateLyrics('all');
                  openFormDrawer = false;
                "
                :disabled="isLoading"
                class="mr1 pa4"
                xs
              >
                Full Song
              </w-button> -->
              <w-button
                @click="generateAll"
                :disabled="isLoading"
                class="mr1 pa4"
                id="generate-all"
                title="Select a random model, then generate the descriptiopn, title, and full song lyrics"
                xs
              >
                I'm Feeling Lucky
              </w-button>
            </div>
            <div class="xs12 pa3">
              <div class="xs">
                <w-alert error icon-outside v-if="errorMessage">
                  {{ errorMessage }}
                </w-alert>
              </div>
            </div>
            <div class="xs12 pa0 ma0">
              <w-progress class="ma0" round v-if="isLoading"></w-progress>
            </div>
          </w-flex>
        </w-drawer>
      </w-card>
      <w-card class="lyrics-container">
        <textarea id="lyrics" v-model="lyrics" style="display: none" />
      </w-card>
      <w-card id="buttons">
        <w-flex>
          <div class="xs6">
            <w-button
              @click="clearLyrics"
              :disabled="isLoading"
              class="mr1 pa2"
              title="Clear all lyrics"
            >
              Clear</w-button
            >
            <w-button
              @click="copyToClipboard"
              :disabled="isLoading"
              class="mr1 pa2"
              title="Copy the song information to the clipboard as formatted text"
              >Copy</w-button
            >
            <!-- <w-button @click="testClick" :disabled="isLoading" class="mr1 pa2"
              >Test</w-button
            > -->
            <!-- <w-button @click="clearAll" :disabled="isLoading" class="mr1 pa4"
            >Clear All</w-button
          > -->
          </div>
          <div class="xs6 text-right">
            <w-flex justify-end>
              <w-transition-scale-fade>
                <w-alert
                  v-if="songSaved"
                  color="success"
                  no-border
                  outline
                  sm
                  class="transition-box mr2 mt0 mb0 pa0"
                  ><w-icon xs>mdi mdi-content-save</w-icon> Saved</w-alert
                >
              </w-transition-scale-fade>
              <w-transition-scale-fade>
                <w-alert
                  v-if="songCopied"
                  color="success"
                  no-border
                  outline
                  sm
                  class="transition-box mr2 mt0 mb0 pa0"
                  ><w-icon xs>mdi mdi-content-copy</w-icon> Copied</w-alert
                >
              </w-transition-scale-fade>
              <!-- <w-button
                @click="saveSong(true)"
                :disabled="isLoading"
                class="mr1 pa2"
                v-if="this.$route.params.id"
                >Save New
              </w-button> -->
              <w-button
                @click="newSong()"
                :disabled="isLoading"
                class="mr1 pa2"
                v-if="this.$route.params.id"
                >New Song</w-button
              >
              <w-button
                @click="createSong"
                :disabled="isLoading"
                class="mr1 pa2"
                v-if="!this.$route.params.id"
                >Save</w-button
              >
              <w-button
                @click="saveSong(false)"
                :disabled="isLoading"
                class="mr1 pa2"
                v-if="songId"
                >Save</w-button
              >
            </w-flex>
          </div>
        </w-flex>
      </w-card>
    </w-form>
  </div>
  <w-button
    absolute
    @click="openWordPanel"
    v-show="showWordButton"
    id="btn-word-info"
    class="ma1"
    bg-color="info"
    icon="mdi mdi-format-letter-case"
  ></w-button>
  <w-progress
    v-if="isLoading"
    absolute
    tile
    style="left: 0; right: 0; margin: auto; font-size: 16px; z-index: 100"
  ></w-progress>
  <w-drawer v-model="showWordDrawer" right :no-overlay="true">
    <w-button
      @click="showWordDrawer = false"
      sm
      outline
      absolute
      round
      icon="wi-cross"
    >
    </w-button>
    <w-card title="Rhymes" v-html="wordText" class="grow pa5"></w-card>
  </w-drawer>
</template>
<script>
// @ is an alias to /src
import { functions, db } from "../firebase";
import { httpsCallable } from "firebase/functions";
import * as codemirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/neat.css";
import "codemirror/mode/gfm/gfm";

import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import {
  doc,
  getDoc,
  //setDoc,
  addDoc,
  updateDoc,
  collection,
} from "firebase/firestore";
import router from "../router";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
// import store from "../store";
// import router from "../router";

export default {
  setup() {
    const route = useRoute();
    const store = useStore();

    const formValid = ref(null);
    const validators = {
      required: (value) => !!value.trim() || "This field is required",
    };

    const songId = ref(route.params.id);

    const user = computed(() => store.state.user);
    const isAuthorized = computed(() => store.state.isAuthorized);
    const authReady = computed(() => store.state.authReady);

    const custom_model = ref("");
    const custom_models = ref([]);

    const songSaved = ref(false);
    const songCopied = ref(false);
    const isLoading = ref(false);
    const openFormDrawer = ref(false);

    const showWordDrawer = ref(false);
    const showWordButton = ref(false);
    const wordText = ref("");
    const selectedLine = ref(0);

    const drawerPosition = computed(() => store.state.drawerPosition || "top");

    const errorMessage = ref("");
    const infoMessage = ref("");

    const lyrics = ref("");
    const title = ref("");
    const summary = ref("");
    const user_temp = ref(0.7);

    let cm = null;

    watch(lyrics, (val) => {
      if (cm) {
        console.log("set lyrics");
        cm.setValue(val);
      }
    });

    onBeforeRouteUpdate(async (to, from) => {
      if (to.params.id !== from.params.id) {
        console.log("onBeforeRouteUpdate", to, from);
        songId.value = to.params.id;
        initCodemirror();
        // set focus to cm
        setTimeout(() => {
          cm.focus();
        }, 100);
      }
    });

    onMounted(async () => {
      console.log("onMounted");
      isLoading.value = true;

      if (songId.value) {
        await loadSong(songId.value);
        initCodemirror();
      } else {
        custom_model.value = "default";
      }

      await getModels();
      isLoading.value = false;
    });

    const initCodemirror = () => {
      console.log("initCodemirror");
      if (!cm) {
        console.log("!cm");
        cm = codemirror.fromTextArea(document.getElementById("lyrics"), {
          lineNumbers: true,
          theme: "default",
          mode: "gfm",
          lineWrapping: true,
          viewportMargin: 10,
          extraKeys: {
            "Ctrl-S": function () {
              saveSong(false); //function called when 'ctrl+s' is used when instance is in focus
            },
            "Ctrl-Enter": function () {
              generateLyrics(); // generate one line
            },
            "Shift-Enter": function () {
              generateLyrics("section"); // generate rest of section
            },
            "Alt-Enter": function () {
              generateLyrics("remaining"); // generate remaining lyrics
            },
          },
        });

        cm.on("cursorActivity", function (cm) {
          var sel = cm.getSelection();
          let words = sel
            .trim()
            .split(" ")
            .filter((r) => r !== "");
          let result = words.length == 1;

          if (result) {
            wordText.value = "";
            selectedLine.value = cm.getCursor().line;

            // position button above current line
            let button = document.getElementById("btn-word-info");
            let start = cm.getCursor(true);
            console.log("start", start);
            let coords = cm.charCoords(
              { line: start.line, ch: start.ch },
              "page"
            );

            button.style.top = coords.top - 35 + "px";
            button.style.left = coords.left + "px";
          }
          showWordButton.value = result;
        });
      }
    };

    const openWordPanel = () => {
      // get rhymes for the word at words[0]

      var sel = cm.getSelection();
      let words = sel
        .trim()
        .split(" ")
        .filter((r) => r !== "");
      let result = words.length == 1;

      if (result) {
        wordText.value = "";

        const getRhymesFunc = httpsCallable(functions, "getRhymes");

        getRhymesFunc({ word: words[0] }).then((result) => {
          // get first ten rhymes
          let rhymes = result.data.slice(0, 30);
          console.log("rhymes", rhymes);
          // display 'word' and 'score' for each rhyme
          let html = "<h2>Rhymes</h2>";
          rhymes.forEach((r) => {
            html += `<br>${r.word}`;
          });
          wordText.value = html;
        });

        showWordDrawer.value = true;
      }
    };

    const summaryChanged = () => {};

    const newSong = () => {
      window.location.href = "/song";
    };

    const loadSong = async (id) => {
      console.log("loadSong", id);
      errorMessage.value = null;
      songSaved.value = false;
      title.value = "";
      summary.value = "";
      lyrics.value = "";
      custom_model.value = "default";
      user_temp.value = 0.7;

      try {
        const songDocRef = doc(db, "songs", id);
        const songDoc = await getDoc(songDocRef);

        if (songDoc.exists()) {
          title.value = songDoc.data().title;
          summary.value = songDoc.data().summary;
          lyrics.value = songDoc.data().lyrics;
          custom_model.value = songDoc.data().custom_model || "default";

          return songDoc;
        } else {
          console.log("No such document!");
          // redirect back to list page
          router.push("/library");
        }
      } catch (error) {
        console.log("Error getting document:", error);
        // redirect back to list page
        router.push("/library");
      }
    };

    const createSong = async () => {
      isLoading.value = true;
      errorMessage.value = null;

      let songRef = null;

      try {
        if (songId.value) {
          songRef = doc(db, "songs", songId.value);
          const songDoc = await getDoc(songRef);
          if (songDoc.exists()) {
            await updateDoc(songRef, {
              lyrics: lyrics.value,
              title: title.value,
              summary: summary.value,
              custom_model: custom_model.value ? custom_model.value : "",
              userId: user.value.uid,
              updated: new Date(),
              deleted: false,
            });
          }
        } else {
          songRef = await addDoc(collection(db, "songs"), {
            title: title.value,
            summary: summary.value,
            lyrics: lyrics.value,
            created: new Date(),
            updated: new Date(),
            custom_model: custom_model.value ? custom_model.value : "",
            userId: user.value.uid,
            deleted: false,
          });
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      isLoading.value = false;
      songSaved.value = true;
      // in 2 seconds set songSaved to false
      setTimeout(() => {
        songSaved.value = false;
      }, 2000);

      songId.value = songRef.id;
      router.push({ name: "song", params: { id: songRef.id } });
    };

    const saveSong = async (isNew = false) => {
      if (formValid.value != null && !formValid.value) {
        openFormDrawer.value = true;
        return;
      }

      isLoading.value = true;
      errorMessage.value = null;
      let songRef = null;
      try {
        if (songId.value && !isNew) {
          songRef = doc(db, "songs", songId.value);
          const songDoc = await getDoc(songRef);
          if (songDoc.exists()) {
            await updateDoc(songRef, {
              lyrics: cm.getValue(),
              title: title.value,
              summary: summary.value,
              custom_model: custom_model.value ? custom_model.value : "",
              userId: user.value.uid,
              updated: new Date(),
              deleted: false,
            });
          }
        } else {
          let oldTitle = "";
          let newTitle = title.value;

          if (songId.value) {
            songRef = doc(db, "songs", songId.value);
            const songDoc = await getDoc(songRef);
            if (songDoc.exists()) {
              oldTitle = songDoc.data().title;
            }
          }
          if (oldTitle == title.value) {
            // if oldTitle ends with a number, increment it
            if (oldTitle.match(/\d+$/)) {
              const num = parseInt(oldTitle.match(/\d+$/)[0]);
              newTitle = oldTitle.replace(/\d+$/, num + 1);
            } else {
              newTitle = oldTitle + " 2";
            }
            title.value = newTitle;
          }

          songRef = await addDoc(collection(db, "songs"), {
            title: title.value,
            summary: summary.value,
            lyrics: cm.getValue(),
            created: new Date(),
            updated: new Date(),
            custom_model: custom_model.value ? custom_model.value : "",
            userId: user.value.uid,
            deleted: false,
          });
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      isLoading.value = false;
      songSaved.value = true;
      // in 2 seconds set songSaved to false
      setTimeout(() => {
        songSaved.value = false;
      }, 2000);
      router.push({ name: "song", params: { id: songRef.id } });
    };

    const getModels = async () => {
      infoMessage.value = "Loading models...";

      const endpointsFunc = httpsCallable(functions, "lyricModels");
      const modelsArray = await endpointsFunc();

      modelsArray.data.forEach((model) => {
        custom_models.value.push({
          label: model.name,
          value: model.value,
          color: model.value.startsWith("genre")
            ? "indigo-dark2"
            : model.value.startsWith("default")
            ? "purple-dark2"
            : "deep-orange-dark4",
        });
      });
      infoMessage.value = null;

      // if custom_model is not empty and custom_model is not in custom_models, set custom_model to default
      if (
        custom_model.value &&
        !custom_models.value.find((item) => item.value === custom_model.value)
      ) {
        custom_model.value = "default";
      }
    };

    const fetchCompletion = async ({
      prompt,
      suffix,
      endpoint,
      temperature,
      max_tokens,
      single_line = false,
      endToken,
    }) => {
      if (!isAuthorized.value) return;
      isLoading.value = true;
      errorMessage.value = "";
      infoMessage.value = "";

      let doRequest = true;
      let numAttempts = 0;
      let response = null;

      while (doRequest) {
        numAttempts++;

        var data = {
          prompt: prompt,
          suffix: suffix,
          endpoint: endpoint,
          temperature: temperature,
          max_tokens: max_tokens,
          single_line: single_line,
          endToken: endToken,
          songId: songId.value,
        };

        try {
          const ai_completion = httpsCallable(functions, "completion");
          response = await ai_completion(data);

          doRequest = false;

          errorMessage.value = "";
          infoMessage.value = "";
          isLoading.value = false;

          return response.data;
        } catch (e) {
          console.log(e);
          // if e.message contains the text "overloaded" or "still being loaded", try again ... generally means the model is "cold"
          if (
            (e.message.includes("overloaded") ||
              e.message.includes("still being loaded")) &&
            numAttempts < 3
          ) {
            doRequest = true;
            infoMessage.value = "Please wait, initializing model...";
            //await new Promise((resolve) => setTimeout(resolve, 500));
          } else {
            doRequest = false;
            errorMessage.value = "Error: " + e.message;
            isLoading.value = false;
            return Promise.reject({ status: e });
          }
        }
      }
    };

    const generateTitle = async () => {
      var prompt = "";
      var ep = "titles";
      const s = summary.value;

      prompt = `I am a song title generator. Given a short summary of the song, I will generate a song title.

Summary: This is a song about ${s}.
Title:`;

      try {
        const response = await fetchCompletion({
          prompt,
          endpoint: ep,
          temperature: 0.5,
          max_tokens: 20,
        });

        var t = response?.choices?.[0]?.text;
        if (t) {
          title.value = t.replaceAll('"', "").trim();
        }
      } catch (error) {
        console.log(error);
        errorMessage.value = "Error: " + error.message;
      } finally {
        isLoading.value = false;
      }
    };

    const generateSummary = async () => {
      isLoading.value = true;

      const data = {
        model: custom_model.value ?? "default",
      };

      const randomSongData = httpsCallable(functions, "randomSongData");
      const response = await randomSongData(data);

      let prompt = ``;
      for (let i = 0; i < response.data.length; i++) {
        prompt += response.data[i].summary + "\n";
      }
      prompt += `This is a song about`;

      try {
        const response = await fetchCompletion({
          prompt,
          endpoint: "davinci",
          temperature: 1,
          max_tokens: 75,
          endToken: "\n",
        });

        var s = response?.choices?.[0]?.text;

        if (s) {
          summary.value = s.trim();
        }
      } catch (error) {
        console.log(error);
        errorMessage.value = "Error: " + error.message;
      }
      isLoading.value = false;
    };

    const generateLyrics = async (genType = "line") => {
      lyrics.value = cm.getValue();

      let max_tokens = 30;
      let single_line = false;
      let endToken = null;
      var param_lyrics = lyrics.value;
      var suffix = "";

      var prompt = `Title: ${title.value}
Description: ${summary.value}
Lyrics:
${param_lyrics}`;

      switch (genType) {
        case "line":
          max_tokens = 20;
          single_line = true;
          break;
        case "edit":
          max_tokens = 45;
          single_line = true;
          //endToken = ["\n", "###"];

          var doc = cm.getDoc();
          var cursor = doc.getCursor();

          param_lyrics = doc.getRange({ line: 0, ch: 0 }, cursor);
          prompt = param_lyrics.trimEnd() + "\n";

          //let pt_start = { line: 0, ch: 0 };
          var pt_cursor = { line: cursor.line, ch: 0 };
          var pt_end = {
            line: doc.lastLine(),
            ch: doc.getLine(doc.lastLine()).length,
          };

          suffix = "\n" + doc.getRange(pt_cursor, pt_end).trimStart();
          custom_model.value = "insert_davinci";

          break;
        case "section":
          max_tokens = 120;
          endToken = ["\n\n", "###"];
          break;
        case "remaining":
          max_tokens = 550;
          break;
        case "all":
          lyrics.value = "";
          cm.setValue("");
          max_tokens = 550;
          prompt = `Title: ${title.value}
Description: ${summary.value}
Lyrics:
`;
          break;
      }

      lyrics.value = cm.getValue();

      try {
        var endpoint =
          custom_model.value != "" ? custom_model.value : "default";

        const params = {
          prompt,
          suffix: genType == "edit" ? suffix : null,
          endpoint: endpoint,
          temperature: user_temp.value,
          max_tokens: max_tokens,
          single_line: single_line,
          endToken: endToken,
        };

        const reponse = await fetchCompletion(params);

        const newText = reponse?.choices?.[0]?.text;
        if (newText && (genType == "line" || genType == "remaining")) {
          lyrics.value += newText;
        } else if (newText && genType == "section") {
          lyrics.value += newText + "\n";
        } else if (newText && genType == "all") {
          lyrics.value = newText;
        }

        // if there are 3 or more newlines in the lyrics, replace them with a single newline
        lyrics.value = lyrics.value.replaceAll("\n\n\n", "\n\n");

        lyrics.value = lyrics.value.trimStart();
      } catch (error) {
        console.log(error);
        errorMessage.value = "Error: " + error.message;
      }

      // setTimeout required to make sure the textarea is updated before scrolling
      setTimeout(() => {
        cm.focus();
        cm.setCursor({ line: cm.lineCount(), ch: 0 });
        cm.scrollIntoView({ line: cm.lineCount() });
      }, 0);
    };

    const generateSongInit = async () => {
      custom_model.value = "";
      title.value = "";
      summary.value = "";

      let models_rand = Math.floor(
        Math.random() * (custom_models.value.length + 1)
      ); //Math.random() * (custom_models.value.length + 5);
      if (models_rand >= custom_models.value.length) {
        custom_model.value = "default";
      } else {
        custom_model.value = custom_models.value[Math.floor(models_rand)].value;
      }

      await generateSummary();
      await generateTitle();

      isLoading.value = false;
    };

    const generateAll = async () => {
      openFormDrawer.value = "top";

      clearAll();

      custom_model.value = "";
      title.value = "";
      summary.value = "";

      let models_rand = Math.floor(
        Math.random() * (custom_models.value.length + 1)
      ); //Math.random() * (custom_models.value.length + 5);
      if (models_rand >= custom_models.value.length) {
        custom_model.value = "default";
      } else {
        custom_model.value = custom_models.value[Math.floor(models_rand)].value;
      }

      await generateSummary();
      await generateTitle();

      setTimeout(() => {
        openFormDrawer.value = false;
      }, 2000);

      await generateLyrics("all");

      isLoading.value = false;
    };

    const onValidate = () => {
      this.form.sent = false;
      this.form.submitted = this.form.errorsCount === 0;
    };

    const copyToClipboard = () => {
      const cur_model = custom_models.value.find((m) => {
        return m.value === custom_model.value;
      });

      let val = "";
      val += "This is a song about " + summary.value + "\n\n";
      val += "Title: " + title.value + "\n\n";
      if (custom_model.value && custom_model.value != "default") {
        val += "(in the style of " + cur_model.label + ")\n\n";
      }
      val += "Lyrics:\n" + cm.getValue();
      navigator.clipboard.writeText(val);

      songCopied.value = true;
      // in 2 seconds set songCopied to false
      setTimeout(() => {
        songCopied.value = false;
      }, 2000);
    };

    const clearLyrics = async () => {
      lyrics.value = "";
    };

    const clearAll = async () => {
      title.value = "";
      custom_model.value = "default";
      summary.value = "";
      lyrics.value = "";
    };

    const testClick = async () => {
      await generateLyrics("edit");
    };

    return {
      songId,

      user,
      isAuthorized,
      authReady,

      formValid,
      validators,
      onValidate,

      custom_model,
      custom_models,

      songSaved,
      songCopied,
      isLoading,
      openFormDrawer,

      showWordButton,
      showWordDrawer,
      wordText,
      openWordPanel,

      selectedLine,
      drawerPosition,
      errorMessage,
      infoMessage,

      lyrics,
      title,
      summary,
      user_temp,

      createSong,
      saveSong,
      newSong,

      generateTitle,
      generateSummary,
      generateLyrics,
      generateSongInit,
      generateAll,

      fetchCompletion,
      summaryChanged,

      testClick,

      getModels,
      copyToClipboard,
      clearLyrics,
      clearAll,
    };
  },
};
</script>

<style>
.w-menu {
  position: sticky;
  top: 10px;
}
.CodeMirror {
  border: 1px solid #eee;
  font-size: 14px;
  font-family: Consolas;
  min-height: 300px;
  height: 100%;
}
.CodeMirror-linenumber {
  font-size: 12px;
}
.CodeMirror-scroll {
  min-height: 300px;
}
span.song-title {
  cursor: pointer;
}
div.song {
  height: 100%;
  flex: 1;
}
div.song.loading .CodeMirror {
  background-color: #efefef;
  color: #ccc;
}
div.song-init {
  font-size: 1.3em;
}
div.lyrics-container {
  height: 100%;
}
input#title {
  font-weight: bold;
  font-size: 1.4em;
}
div.song input {
  font-size: 1.2em;
}
div.song textarea {
  font-size: 1.2em;
}
</style>

