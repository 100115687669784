<template>
  <div class="list">
    <template v-if="user">
      <w-card class="ma1" no-border>
        <h1>YOUR SONGS</h1>
        <div v-if="isLoading">
          <w-progress class="ma1"></w-progress>
        </div>
        <div v-if="!isLoading">
          <div v-if="songsList.length === 0" class="text-center">
            <p class="pa10">
              You haven't saved any songs yet. What are you waiting for?
            </p>
            <w-button
              xl
              text
              shadow
              bg-color="primary"
              :route="'/song'"
              class="ma2"
            >
              New Song
            </w-button>
          </div>
          <w-card
            class="text-right"
            v-if="!isLoading && songsList.length > 0"
            no-border
          >
            <w-button
              xl
              text
              shadow
              bg-color="primary"
              :route="'/song'"
              class="ma2"
            >
              New Song
            </w-button>
          </w-card>
          <div v-for="song in songsList" :key="song.id">
            <w-card tile bg-color="white" class="mb5">
              <template #title>
                <w-flex class="justify-space-between pa3 primary--bg">
                  <div class="secondary text-left">
                    <router-link
                      class="secondary"
                      :to="{ name: 'song', params: { id: song.id } }"
                    >
                      {{ song.title }}
                    </router-link>
                    <!-- <w-button lg text :route="song.link" color="secondary">{{
                      song.title
                    }}</w-button> -->
                  </div>
                  <div class="text-right">
                    <!-- <w-button
                    height="100%"
                    md
                    text
                    bg-color="primary"
                    :route="song.link"
                    class="mr2"
                    >edit</w-button
                  > -->
                    <w-button
                      height="100%"
                      lg
                      text
                      color="error"
                      @click="deleteSong(song.id)"
                    >
                      <w-icon>mdi mdi-delete</w-icon></w-button
                    >
                  </div>
                </w-flex>
              </template>
              <p>
                This song is about <strong>{{ song.summary }}</strong>
              </p>
              <!-- <p>
              <span v-html="song.lyrics"></span>
            </p> -->
            </w-card>
          </div>
        </div>
      </w-card>
    </template>
    <template v-else>
      <w-card>
        <p>You must be logged in to view this page.</p>
        <p><w-button route="/login">Register or login</w-button></p>
      </w-card>
    </template>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { db } from "../firebase";
import {
  doc,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  getDoc,
  updateDoc,
} from "firebase/firestore";

export default {
  setup() {
    const store = useStore();

    const songsList = ref([]);
    const isLoading = ref(false);

    const deleteSong = async (id) => {
      if (!confirm("Are you sure you want to delete this song?")) return;
      const songDocRef = doc(db, "songs", id);
      const songDoc = await getDoc(songDocRef);
      if (songDoc.exists()) {
        await updateDoc(songDocRef, {
          updated: new Date(),
          deleted: true,
        });
        getSongs(store.state.user.uid);
      }
    };

    const getSongs = async (id) => {
      isLoading.value = true;

      // get songs from firestore for this user
      const songsRef = collection(db, "songs");
      const q = query(
        songsRef,
        where("userId", "==", id),
        where("deleted", "==", false),
        orderBy("updated", "desc")
      );

      const shot = await getDocs(q);
      const docsData = shot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      const newArray = docsData.map((o) => {
        const title = o.title;
        const id = o.id;
        const link = "/song/" + o.id;
        const summary = o.summary;
        const lyrics = o.lyrics;

        return {
          id,
          title,
          summary,
          link,
          lyrics: lyrics.replaceAll("\n", "<br>"),
        };
      }, []);

      songsList.value = newArray;
      isLoading.value = false;
    };

    onMounted(() => {
      if (store.state.user) {
        getSongs(store.state.user.uid);
      }
    });

    watch(
      () => store.state.user,
      () => {
        if (store.state.user) {
          getSongs(store.state.user.uid);
        }
      }
    );

    return {
      songsList,
      deleteSong,
      isLoading,
      user: computed(() => store.state.user),
      authReady: computed(() => store.state.authReady),
    };
  },
};
</script>

<style scoped>
</style>