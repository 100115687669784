<template>
  <div class="home">
    <w-flex wrap class="fill-width">
      <div class="xs12 pt10 huger text-center">
        <w-icon>mdi mdi-robot-confused</w-icon>
      </div>
      <div class="xs12 pa3 huge text-center logo-text">songsmith.ai</div>
      <div class="xs12 pa3 mt10 text-center">
        <w-button class="pa6" :route="getStartedLink">
          <w-icon class="mr2">mdi mdi-bookshelf</w-icon>Get Started</w-button
        >
      </div>
    </w-flex>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const getStartedLink = computed(() => {
      const store = useStore();
      const user = store.state.user;
      if (user) {
        return "/library";
      } else {
        return "/login";
      }
    });

    return {
      getStartedLink,
    };
  },
};
</script>

<style scoped>
.home {
  padding-top: 50px;
}
.logo-text {
  font-size: 2em;
  font-weight: bold;
  color: #333;
}
.huger {
  font-size: 10em;
}
</style>