import { createStore } from "vuex";
import router from "../router";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithRedirect,
  getRedirectResult,
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { signInWithPopup, OAuthProvider, GoogleAuthProvider, FacebookAuthProvider, promptUserForPassword, linkWithCredential, goToApp, fetchSignInMethodsForEmail } from "firebase/auth";

const store = createStore({
  state: {
    user: null,
    authReady: false,
    isAuthorized: false,
  },
  mutations: {
    async setUser(state, user) {
      state.user = user;
      state.isAuthorized = await getIsAuthorized(user);
      // console.log("user set to: ", (user?.email ?? "n/a"));
      // console.log("isAuthorized set to: ", state.isAuthorized);
    },
    clearUser(state) {
      // console.log("user cleared: " + (state?.user ?? "n/a"));
      state.user = null;
    },
    setAuthReady(state, payload) {
      state.authReady = payload;
    }
  },
  actions: {
    async init(context) {
      // console.log("init auth");
      const result = await getRedirectResult(auth);
      // console.log("getRedirectResult", result);
      if (result?.user) {
        const user = result.user;
        context.commit("setUser", user);
        await saveUserData(user);
        router.push("/library");
      }
    },
    async googleLogin(context) {
      const provider = new GoogleAuthProvider();

      //const auth = getAuth();
      signInWithRedirect(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          //var credential = GoogleAuthProvider.credentialFromResult(result);
          //const token = credential.accessToken;

          // The signed-in user info.
          //const user = result.user;

          context.commit("setUser", result.user);
          saveUserData(result.user);

          router.push("/library");
        })
        .catch((error) => {
          console.log(error);
        });

    },
    async facebookLogin(context) {
      const provider = new FacebookAuthProvider();

      //const auth = getAuth();
      signInWithRedirect(auth, provider)
        .catch(async function (error) {
          console.log(error);
          if (error.code == 'auth/account-exists-with-different-credential') {

            var credential = OAuthProvider.credentialFromError(error);
            // The provider account's email address.
            var email = error.customData.email;
            // Get sign-in methods for this email.
            fetchSignInMethodsForEmail(auth, email)
              .catch(function (error) {
                console.log(error);
              })
              .then(function (methods) {
                // Step 3.
                // If the user has several sign-in methods,
                // the first method in the list will be the "recommended" method to use.
                if (methods[0] === 'password') {
                  // Asks the user their password.
                  // In real scenario, you should handle this asynchronously.
                  var password = promptUserForPassword(); // TODO: implement promptUserForPassword.
                  signInWithEmailAndPassword(auth, email, password).then(function (result) {
                    // Step 4a.
                    return result.user.linkWithCredential(credential);
                  }).then(function (result) {
                    context.commit("setUser", result.user);
                    saveUserData(result.user);

                    router.push("/library");
                  });
                  return;
                }
                // All the other cases are external providers.
                var provider = getProviderForProviderId(methods[0]);
                // At this point, you should let the user know that they already have an account
                // but with a different provider, and let them validate the fact they want to
                // sign in with this provider.
                // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
                // so in real scenario you should ask the user to click on a "continue" button
                // that will trigger the signInWithPopup.
                signInWithPopup(auth, provider).then(function (result) {
                  // Remember that the user may have signed in with an account that has a different email
                  // address than the first one. This can happen as Firebase doesn't control the provider's
                  // sign in flow and the user is free to login using whichever account they own.
                  // Step 4b.
                  // Link to Facebook credential.
                  // As we have access to the pending credential, we can directly call the link method.
                  linkWithCredential(result.user, credential).then(function (user) {
                    // Facebook account successfully linked to the existing Firebase user.                    
                    console.log("user", user);
                    goToApp();
                  });
                });
              });
          }
        })
        .then((result) => {
          // The signed-in user info.
          //const user = result.user;
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          //const credential = FacebookAuthProvider.credentialFromResult(result);
          //const accessToken = credential.accessToken;
          if (result) {
            context.commit("setUser", result.user);
            saveUserData(result.user);

            router.push("/library");
          }
        })
    },
    async login(context, payload) {
      const { email, password } = payload;
      var result = null;
      try {
        result = await signInWithEmailAndPassword(auth, email, password);
      }
      catch (error) {
        switch (error.code) {
          case "auth/user-not-found":
            alert("User not found");
            break;
          case "auth/wrong-password":
            alert("Wrong password");
            break;
          default:
            alert("Something went wrong");
            break;
        }
        return;
      }
      // login successful
      if (result) {
        context.commit("setUser", result.user);
        saveUserData(result.user);
      } else {
        throw new Error("Something went wrong");
      }

      router.push("/library");
    },
    async logout(context) {
      await signOut(auth);
      context.commit("clearUser");
      router.push("/");
    },
    async register(context, payload) {
      const { email, password } = payload;
      var result = null;
      try {
        result = await createUserWithEmailAndPassword(auth, email, password);
      }
      catch (error) {
        switch (error.code) {
          case "auth/email-already-in-use":
            alert("Email already in use");
            break;
          case "auth/invalid-email":
            alert("Invalid email");
            break;
          case "auth/operation-not-allowed":
            alert("Operation not allowed");
            break;
          case "auth/weak-password":
            alert("Weak password");
            break;
          default:
            alert("Something went wrong");
            break;
        }
        return;
      }
      if (result) {
        context.commit("setUser", result.user);

        try {
          saveUserData(result.user);
          router.push("/library");
        } catch (e) {
          console.error("Error adding user: ", e);
        }
      }
      else {
        throw new Error("Something went wrong");
      }
    },

  },
});

function getProviderForProviderId(providerId) {
  switch (providerId) {
    case 'google.com':
      return new GoogleAuthProvider();
    case 'facebook.com':
      return new FacebookAuthProvider();
    default:
      return null;
  }
}
async function getIsAuthorized(user) {
  let isauth = true;
  if (user) {
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      // if userDoc.data().isAuthorized is null, set to true
      if (userDoc.data().isAuthorized === undefined || userDoc.data().isAuthorized === null) {
        isauth = true;
      } else {
        isauth = await userDoc.data().isAuthorized;
      }
    }
  }
  return await isauth;
}

async function saveUserData(user) {
  try {
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {

      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        registerDate: new Date(),
        lastLoginDate: new Date(),
        isAuthorized: true,
      });

    } else {
      // update user
      await updateDoc(userDocRef, {
        displayName: user.displayName ? user.displayName : userDoc.data().displayName,
        photoURL: user.photoURL ? user.photoURL : userDoc.data().photoURL,
        lastLoginDate: new Date()
      });
    }

  } catch (e) {
    console.error("Error adding user: ", e);
  }
}



const unsub = onAuthStateChanged(auth, (user) => {
  if (user) {
    store.commit("setAuthReady", true);
    store.commit("setUser", user);
    unsub(); // unsubscribe so only fires once
  }
});

export default store;