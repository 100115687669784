<template>
  <div class="profile text-center">
    <template v-if="userInfo">
      <w-card id="profile" class="pa4 user-card">
        <img :src="userInfo.photoURL" class="" alt="avatar" v-if="userInfo" />
        <w-flex class="justify-space-between">
          <div class="text-left">
            <h1>{{ userInfo.displayName }}</h1>
            <div class="user-info body text-left">
              <p class="pa0 ma0">
                <strong>Registered:</strong>
                {{ userInfo.registerDate.toDate().toLocaleString() }}
              </p>
              <p class="pa0 ma0">
                <strong>Last Login:</strong>
                {{ userInfo.lastLoginDate.toDate().toLocaleString() }}
              </p>
              <p class="pa0 ma0">
                <strong>Saved Songs:</strong>
                {{ userInfo.numSongs }}
              </p>
            </div>
          </div>
          <div class="text-right">
            <p class="user-email">
              {{ user.email }}<br />
              <w-icon
                v-for="method in userInfo.signInMethods"
                v-bind:key="method"
              >
                mdi mdi-{{ method.replace(".com", "") }}
              </w-icon>
            </p>
            <w-button md class="pa1" @click="$store.dispatch('logout')"
              >Logout</w-button
            >
          </div>
        </w-flex>
      </w-card>
    </template>
  </div>
</template>

<script>
import md5 from "md5";
import { onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { auth, db } from "../firebase";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";

export default {
  setup() {
    const store = useStore();
    const userInfo = ref(null);

    const getProfileInfo = async () => {
      const user = store.state.user;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          userInfo.value = userDoc.data();
          // create gravatar hash
          const hash = md5(user.email);
          const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?s=200&d=robohash&f=y`;
          userInfo.value.photoURL = gravatarUrl;

          if (!userInfo.value.displayName && user.displayName) {
            userInfo.value.displayName = user.displayName;
          } else if (!userInfo.value.displayName && !user.displayName) {
            userInfo.value.displayName = user.email.split("@")[0];
          }

          // get songs from firestore for this user
          const songsRef = collection(db, "songs");
          const q = query(
            songsRef,
            where("user", "==", userDocRef),
            where("deleted", "==", false)
          );

          const shot = await getDocs(q);
          userInfo.value.numSongs = shot.size;

          const methods = await fetchSignInMethodsForEmail(auth, user.email);
          userInfo.value.signInMethods = methods;
        }
      }
    };

    watch(
      () => store.state.user,
      async (user) => {
        if (user) {
          await getProfileInfo();
        }
      }
    );

    onMounted(() => {
      getProfileInfo();
    });

    return {
      user: computed(() => store.state.user),
      userInfo,
    };
  },
};
</script>

<style>
#profile {
  max-width: 550px;
}

#profile h1 {
  padding-left: 0;
}
.user-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
}

.user-card img {
  width: 100%;
  border: 5px solid #333;
}
.user-email {
  text-align: right;
}
.title {
  color: grey;
  font-size: 18px;
}
</style>