<template>
  <w-form class="paste" @submit.prevent="importSong" :disabled="isLoading">
    <h2>Import Song</h2>
    <w-textarea
      placeholder="Paste formatted song data here"
      v-model="songText"
      class="mb5"
    />
    <w-button type="submit" value="Import" :disabled="isLoading"
      >Import Song</w-button
    >
  </w-form>
</template>

<script>
// @ is an alias to /src
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { doc, addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

export default {
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const songText = ref("");
    const user = computed(() => store.state.user);

    const importSong = async () => {
      console.log("Importing song...");
      isLoading.value = true;
      // let val = "";
      // val += "This is a song about " + summary.value + "\n\n";
      // val += "Title: " + title.value + "\n\n";
      // if (custom_model.value && custom_model.value != "default") {
      //   val += "(in the style of " + cur_model.label + ")\n\n";
      // }
      // val += "Lyrics:\n" + cm.getValue();

      // trim songText
      //songText.value = songText.value.trim();

      // if songText does not contain 'This is a song about', 'Lyrics:', or 'Title:', then it is not a valid song
      if (songText.value.indexOf("This is a song about") == -1) {
        alert("Song text does not contain 'This is a song about'");
        isLoading.value = false;
        return;
      }
      if (songText.value.indexOf("Lyrics:") == -1) {
        alert("Song text does not contain 'Lyrics:'");
        isLoading.value = false;
        return;
      }
      if (songText.value.indexOf("Title:") == -1) {
        alert("Song text does not contain 'Title:'");
        isLoading.value = false;
        return;
      }

      // get all text in songText from the text 'This is a song about ' to the first newline
      let description = songText.value
        .substring(
          songText.value.indexOf("This is a song about ") + 20,
          songText.value.indexOf("\n\n")
        )
        .trim();
      // get all text in songText from the text 'Title:' until the first newline
      let title = songText.value
        .substring(
          songText.value.indexOf("Title:") + 6,
          songText.value.indexOf("\n", songText.value.indexOf("Title:"))
        )
        .trim();
      // get all text in songText from the text 'Lyrics:' until the end of the string
      let lyrics = songText.value
        .substring(songText.value.indexOf("Lyrics:") + 7)
        .trim();

      console.log("* * * * * * * * * *");
      console.log("DESCRIPTION:\n" + description);
      console.log("TITLE:\n" + title);
      console.log("LYRICS:\n" + lyrics);
      console.log("* * * * * * * * * *");

      if (!title || !lyrics || !description) {
        alert(
          "Make sure that the text you pasted is formatted correctly. It must contain description, title and lyrics."
        );
        isLoading.value = false;
        return;
      }

      let songRef = null;
      try {
        songRef = await addDoc(collection(db, "songs"), {
          title: title,
          summary: description,
          lyrics: lyrics,
          created: new Date(),
          updated: new Date(),
          custom_model: "",
          user: doc(db, "users", user.value.uid),
          deleted: false,
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      console.log("Song added: " + songRef.id);
      isLoading.value = false;
    };

    return {
      isLoading,
      songText,
      importSong,
      user: computed(() => store.state.user),
    };
  },
};
</script>

<style>
form.paste h2 {
  text-transform: uppercase;
  margin: 2rem 0;
}
form.paste input#title {
  font-weight: bold;
  font-size: 1.4em;
}

form.paste textarea {
  font-size: 1.2em;
  padding: 40;
}
</style>